
import { Item, IItemData } from '@/components/ibx/base/Item.ts'
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import IbxCardMetaBase, { ICardMeta } from '@/components/ibx/cardMeta/IbxCardMetaBase.vue'

@Component
export default class IbxCardMetaItem extends IbxCardMetaBase<Item, IItemData> implements ICardMeta {
  get id(): string {
    return this.idLabel(this.entity.id, 'Item')
  }
  get revisionId(): string {
    return this.idLabel(this.entity.itemRevId, 'Item Rev')
  }
  get media(): string {
    return this.data.meta?.media?.items[0]['name']
  }

  mediaTypeLabel() {
    if (this.media) {
      return `Media Type: ${this.media ? this.media : ''}`
    } else {
      const printItemTypes = this.featureFlag(this.FLAG.PRINT_ITEM_TYPES).itemTypes
      const mediaType = printItemTypes.includes(this.data.itemType) ? 'Paper & Web' : 'Web Only'
      return `Media Type: ${mediaType}`
    }
  }

  created() {
    this.initialize(Item)
  }
}
