<template>
  <v-main class="ibx-item-card-standalone">
    <div v-if="!hasId && !hasError">Please specifiy an itemId, itemRevId or assessmentId with itemRevId</div>
    <div v-else-if="hasError" class="error--text ml-2 mt-2">
      <strong>Error: {{ errorMessage }}</strong>
    </div>
    <div v-else>
      <div v-if="controls" class="ibx-item-card-standalone__control">
        <a class="ibx-item-card-standalone__togglePassage" @click="showPassage = !showPassage">{{
          passageControlLabel
        }}</a>
      </div>
      <ibx-passage-card
        v-if="!fetching && hasPassage && showPassage"
        :passage="passage"
        view="expand"
        :actions="[]"
        :showItemCount="false"
        heightAuto
        noFooter
      />
      <div :class="itemWrapperClass">
        <item-card
          v-if="!fetching"
          :itemData="item"
          :preview="preview"
          context="provide"
          lazy
          :lazyLoadTrigger="true"
          view="expand"
        />
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IBXService from '@services/ibx/IBXService'
import ItemCard from '@components/itemCardStandalone/ItemCard'
import '@icons'
import _ from 'lodash'

export default {
  name: 'ItemCardView',

  components: {
    ItemCard,
    'ibx-passage-card': () => import('@components/ibx/_IbxPassageCard'),
  },

  data: () => ({
    item: null,
    passage: null,
    fetching: true,
    hasError: false,
    errorMessage: 'There was an error with your request',
    controls: false,
    showPassage: false,
    preview: true,
  }),

  computed: {
    ...mapGetters('auth', {
      authPieToken: 'pieToken',
    }),
    hasId() {
      return Boolean(this.getItemId || this.getItemRevId || this.getAsmtId)
    },
    getItemId() {
      return this.$route.query.itemId
    },
    getItemRevId() {
      return this.$route.query.itemRevId
    },
    getAsmtId() {
      return this.$route.query.assessmentId
    },
    hasPassage() {
      return Boolean(this.passage)
    },
    itemWrapperClass() {
      const classes = ['ibx-item-card-standalone__item-wrapper']
      if (this.showPassage) classes.push('ibx-item-card-standalone__item-wrapper--show-passage')
      return classes
    },
    passageControlLabel() {
      return this.showPassage ? 'Hide Passage' : 'Show Passage'
    },
  },

  methods: {
    ...mapActions('auth', {
      authInit: 'init',
    }),
    getParam(param) {
      return this.$route.query[param]
    },
    async getItemByItemRevId() {
      try {
        await this.authInit()
        const { item } = await IBXService.itemRevisions(this.getItemRevId)
        this.item = item
        this.passage = this.item.passage
        this.controls = this.hasPassage
      } catch (error) {
        this.hasError = true
        console.warn(error)
      } finally {
        this.fetching = false
      }
    },
    /**
     * Get item by assessment and question/item data
     * If itemRevId is not in assessment then get question by itemId for givev itemRevId
     * If no match then throw and error
     * Merges item and question standards/meta
     */
    async getItemByAssessment() {
      try {
        await this.authInit()

        // fetch assessment and attempt to find question by itemRevId
        const { questions } = await IBXService.assessmentItems({ assessmentId: this.getAsmtId })
        let question = questions.find((o) => o.itemRevId == this.getItemRevId)
        let item = question?.item

        // if itemRevId not in latest assessmet then find question by itemId
        if (!question) {
          const res = await IBXService.itemRevisions(this.getItemRevId)
          item = res.item
          question = questions.find((o) => o.item.itemId == item.itemId)
        }

        // if question found, override items standard with question standards, else throw error
        if (question) {
          item = this.overrideItemStdsWithQuestionStds(item, question.standards)
          this.item = item
          this.passage = this.item.passage
          this.controls = this.hasPassage
        } else {
          throw `itemRevId ${this.getItemRedId} not in assessment ${this.assessmentId}`
        }
      } catch (error) {
        this.hasError = true
        console.warn(error)
      } finally {
        this.fetching = false
      }
    },
    overrideItemStdsWithQuestionStds(item = { meta: {} }, questionStandards = []) {
      questionStandards = questionStandards.map((guid) => ({ id: guid, name: '', value: null }))
      item.meta.standard = { id: 'standard', name: 'Standard', items: questionStandards }
      return item
    },
  },

  created() {
    if (this.getAsmtId) {
      this.getItemByAssessment()
    } else if (this.hasId) {
      this.getItemByItemRevId()
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.ibx-item-card-standalone {
  &__item-wrapper--show-passage {
    padding: 10px;
    background: $N20;
  }

  &__control {
    margin: 10px 0px 10px 0px;
  }
  &__togglePassage {
    padding: 20px;
    cursor: pointer;
  }
}
</style>
