/**
 * Calculate item weight based on item rubric.
 * @param config PIE item config
 * @returns number item weight based on max rubric
 * @return For ML rubric weight calculation is : (maxPoint * number of trait) for each scale.
 */
import _ from 'lodash'
export const calculateItemWeight = (config: any): number => {
  return config?.models?.reduce((m, model) => {
    if (model?.points?.length) {
      m = Math.max(...model.points.map((_, i) => i))
    } else if ((model?.rubricType && model?.rubricType == 'simpleRubric') || model?.rubrics?.simpleRubric) {
      const offset = model?.rubrics?.simpleRubric?.excludeZero ? 0 : 1
      m = model?.rubrics?.simpleRubric?.points?.length - offset
    } else if ((model?.rubricType && model?.rubricType == 'multiTraitRubric') || model?.rubrics?.multiTraitRubric) {
      let point = 0
      model?.rubrics?.multiTraitRubric?.scales.forEach((element) => {
        let maxPoint = element.maxPoints
        point += maxPoint * element.traits.length
      })
      m = point
    }
    return m
  }, 1)
}

/**
 * Check if item type supports rubrics
 * @param itemType string
 * @returns boolean
 */
export const supportsRubric = (itemType: string): boolean => {
  return ['constructed_response', 'drawing_response'].includes(itemType)
}

/**
 * Check if item has rationale enabled
 * @param config PIE item config
 * @returns boolean
 */
export const hasRationale = (config: any): boolean => {
  return config?.models?.reduce((m, model) => {
    if (model?.rationaleEnabled) {
      m = model.rationaleEnabled
    }
    return m
  }, false)
}

/**
 * Check if item has partialScoring enabled
 * @param config PIE item config
 * @returns boolean
 */
export const hasPartialScoring = (config: any): boolean => {
  if (config?.models == undefined) return true
  return config?.models?.reduce((m, model) => {
    if (model?.partialScoring != undefined) {
      m = model.partialScoring
    }
    return m
  }, true)
}

/**
 * Get item rationale markup wrapped in helper classes.
 * @param config PIE item config
 * @returns string[]
 */
export const rationales = (config: any): string[] => {
  return config?.models?.reduce((m, model) => {
    if (model?.choices?.length) {
      model.choices.forEach((choice) => {
        m.push(`<span class="label">${choice.label}</span><span class="txt">${choice.rationale}</span>`)
      })
    }
    return m
  }, [])
}

/**
 * Get item correct options
 * @param config PIE item config
 * @returns string[]
 */
export const correct = (config: any): string[] => {
  return config?.models?.reduce((m, model) => {
    if (model?.choices?.length) {
      model.choices.forEach((choice) => {
        if (choice.correct) {
          m.push(choice.label)
        }
      })
    }
    return m
  }, [])
}

/**
 * Check if item has simple rubric enabled
 * @param config PIE config
 * @returns boolean
 */
export const checkIfSimpleRubric = (config: any): boolean => {
  if (config?.models == undefined) return false

  return config?.models.length > 1 && config?.models[1]['rubricType'] == 'multiTraitRubric' ? false : true
}

/**
 * Check if authoring items has Multi-level rubric.
 * @param config author Items list
 * @returns boolean
 */
export const checkForMultiLevelRubric = (authorItems: any): boolean => {
  let isItemContainsMLRubric = false
  for (let i = 0; i <= authorItems.length - 1; i++) {
    let data = authorItems[i]?.data

    if (data.itemType == 'constructed_response') {
      let type = data?.contentConfig?.models[1]?.rubricType
      if (type == 'multiTraitRubric') {
        isItemContainsMLRubric = true
      }
    }
  }
  return isItemContainsMLRubric
}

const metaAllowMultiple: { [k: string]: boolean } = { bank: true }

export const tagsDisplay = (list: any[], sort: boolean = true) => {
  // create single item
  const createTag = (data: { items: any[]; name: string }): string => {
    const value = data.items ? data.items[0].name : 'N/A'
    return `${data.name}: ${value}`
  }

  // create piped tag list
  const createTagList = (data: { items: any[]; name: string }): string => {
    if (!data?.items?.length) {
      return ''
    }
    const items = data.items.map((o: any) => o.name || 'N/A').join(' | ')
    return `${data.name}s: ${items}`
  }
  const tags = (sort ? _.sortBy(list, ['name']) : list)
    .filter((o: any) => o.id !== 'media')
    .map((o: any) => (metaAllowMultiple[o.id] && o?.items?.length > 1 ? createTagList(o) : createTag(o)))
  return [...tags]
}
